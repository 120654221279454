import React from "react"
import styled from "styled-components"
import {Container} from 'reactstrap'
import { graphql, StaticQuery, Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import PhoneImg from "../assets/images/green-skew-3.png"

/*

<HeaderForm onSubmit={handleSubmit}>
  <HeaderInput placeholder="Your email" />
  <HeaderButton>Early access</HeaderButton>
</HeaderForm>

*/

/* 

<FormSubtitle>
  Already have a beta account?{" "}
  <FormSubtitleLink to="/">Начать работу</FormSubtitleLink>
</FormSubtitle>

*/


const Slider = ({data}) => {
  const handleSubmit = event => {
    event.preventDefault()
  }

  return (
    <Container className="slider-container">
      <Flex>
        <HeaderTextGroup>
          <Title1 className="font-weight-bold">
            <span>Автоматизируйте</span> процесс&nbsp;взыскания
          </Title1>
          <h2 className="font-weight-bold">
            Сервис осуществляет мониторинг данных физических и юридических лиц  
          </h2>
          <HeaderForm onSubmit={handleSubmit}>
            <HeaderButton href="https://my.debtsoft.ru" target="_blank">Начать работу</HeaderButton>
          </HeaderForm>
        </HeaderTextGroup>
        <ImageWrapper>
          <StyledImage src={PhoneImg} alt="Телефон"/>
          <br />
        </ImageWrapper>
      </Flex>
    </Container>
  )
}

const SliderQuery = () => (
  <StaticQuery
    query={graphql`
      query SliderQuery {
        file(relativePath: {eq: "green-skew-2.png"}) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `}
    render={data => (
      <Slider data={data}/>
    )}
  />
)

export default SliderQuery

const Title1 = styled.h1`
  font-family: 'Raleway';
  font-size: 2.5rem;

  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 2rem;
  }
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  padding-top: 1.5rem;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
`

const FormSubtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  color: ${props => props.theme.primary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.primary};
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.primary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.primary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.a`
  font-weight: 500;
  font-size: 1.25em;
  line-height: 3em;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.primary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    color: white;
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    text-decoration: none;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.img`
  max-width: 500px;
  width: 100%;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`
